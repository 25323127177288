<template>
  <PageLayout title="Gardinen & Dekoration">
    <p>Gardinen verleihen einem Raum Charakter und Ambiente. Wünschen Sie sich eine Veränderung in Ihrem Zuhause? Ich helfe Ihnen dabei, Ihren Räumen eine persönliche Note zu verleihen und biete eine Vielzahl ausgewählter Produkte an.</p>
      <ul>
          <li>Beratung und Aufmaß vor Ort</li>
          <li>Erstellung von maßgeschneiderten Dekorationsplänen</li>
          <li>Anfertigung und Nähen von Gardinen und Dekorationen</li>
          <li>Pflege und Reinigung Ihrer Fensterdekorationen</li>
      </ul>
    <ImageGallery :images="images" />
  </PageLayout>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useHead } from '@vueuse/head';
import ImageGallery from '@/components/ImageGallery.vue';
import PageLayout from '@/components/PageLayout.vue';

export default {
  name: 'GardinenPage',
  components: {
    ImageGallery,
    PageLayout
  },
  setup() {
    const images = ref([]);

    const loadImage = async (imagePath) => {
      const image = await import(`@/assets/images/gardinen/${imagePath}`);
      return image.default;
    };

    onMounted(async () => {
      const req = require.context('@/assets/images/gardinen/', false, /\.webp$/);
      for (const filePath of req.keys()) {
        const imageName = filePath.replace('./', '');
        const image = await loadImage(imageName);
        images.value.push(image);
      }
    });

    useHead({
      title: 'Gardinen & Dekoration - Raumausstatter Heinz',
      meta: [
        {
          name: 'description',
          content: 'Erleben Sie bei Raumausstatter Heinz die Wirkung von maßgeschneiderten Gardinen & Dekorationen. Wir bieten Beratung, Aufmaß vor Ort, individuelle Dekorationspläne und Pflege Ihrer Fensterdekorationen. Verleihen Sie Ihren Räumen in der Region Aue/Schwarzenberg und dem Erzgebirge eine persönliche Note.'
        },
        {
          property: 'og:description',
          content: 'Erleben Sie bei Raumausstatter Heinz die Wirkung von maßgeschneiderten Gardinen & Dekorationen. Wir bieten Beratung, Aufmaß vor Ort, individuelle Dekorationspläne und Pflege Ihrer Fensterdekorationen. Verleihen Sie Ihren Räumen in der Region Aue/Schwarzenberg und dem Erzgebirge eine persönliche Note.'
        }
      ]
    });

    return { images };
  },
};
</script>


<style scoped>
.outer_wrap {
  background: url("@/assets/images/homeslider/RaumausstatterHeinz_Gardinen.webp") repeat scroll 0 0 / contain  content-box;
}
</style>