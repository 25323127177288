import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import App from './App.vue'
import router from './router'
import { register } from 'swiper/element/bundle'

register()

const head = createHead({
  title: 'Raumausstatter Heinz - Individuelle Raumausstattung und Inneneinrichtung', 
  meta: [
    {
      name: 'description',
      content: 'Raumausstatter Heinz aus Eibenstock bietet individuelle Raumausstattung und Inneneinrichtung für Eibenstock und Umgebung. Besuchen Sie uns für Gardinen, Polster, Sonnenschutz und Raumtextilien.'
    },
    {
      property: 'og:title',
      content: 'Raumausstatter Heinz - Individuelle Raumausstattung und Inneneinrichtung'
    },
    {
      property: 'og:description',
      content: 'Raumausstatter Heinz aus Eibenstock bietet individuelle Raumausstattung und Inneneinrichtung für Eibenstock und Umgebung. Besuchen Sie uns für Gardinen, Polster, Sonnenschutz und Raumtextilien.'
    },
    {
      property: 'og:type',
      content: 'website'
    },
    // Here you can add any other global meta tags.
  ]
})

const app = createApp(App).use(router).use(head)
app.config.globalProperties.$scrambleEmail = function(email) {
  const shift = 5; 
  const aCharCode = 'a'.charCodeAt(0);
  const zCharCode = 'z'.charCodeAt(0);
  const ACharCode = 'A'.charCodeAt(0);
  const ZCharCode = 'Z'.charCodeAt(0);

  const scrambledEmail = email.split('').map(char => {
    const charCode = char.charCodeAt(0);

    if (charCode >= aCharCode && charCode <= zCharCode) {
      return String.fromCharCode(aCharCode + (charCode - aCharCode + shift) % 26);
    } else if (charCode >= ACharCode && charCode <= ZCharCode) {
      return String.fromCharCode(ACharCode + (charCode - ACharCode + shift) % 26);
    } else {
      return char;
    }
  }).join('');

  return scrambledEmail;
}

app.config.globalProperties.$unscrambleEmail = function(scrambledEmail) {
  const shift = 5;
  const aCharCode = 'a'.charCodeAt(0);
  const zCharCode = 'z'.charCodeAt(0);
  const ACharCode = 'A'.charCodeAt(0);
  const ZCharCode = 'Z'.charCodeAt(0);

  const email = scrambledEmail.split('').map(char => {
    const charCode = char.charCodeAt(0);

    if (charCode >= aCharCode && charCode <= zCharCode) {
      return String.fromCharCode(aCharCode + (charCode - aCharCode - shift + 26) % 26);
    } else if (charCode >= ACharCode && charCode <= ZCharCode) {
      return String.fromCharCode(ACharCode + (charCode - ACharCode - shift + 26) % 26);
    } else {
      return char;
    }
  }).join('');

  return email;
}

app.mount('#app')
