<template>
  <PageLayout title="Polster">
    <p>"Aus Alt mach Neu"? Ob abgenutzte Bezüge, ausgediente Federkerne oder durchgesessene Polster - ich kümmere mich mit Freude um Ihr liebgewonnenes Möbelstück und verleihe ihm ein neues Leben. Unsere Dienstleistungen umfassen:</p>
    <ul>
        <li>Aufpolstern und Beziehen</li>
        <li>Abholung und Lieferung</li>
        <li>Schaumstoffzuschnittservice</li>
    </ul>
    <ImageGallery :images="images" />
  </PageLayout>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useHead } from '@vueuse/head';
import ImageGallery from '@/components/ImageGallery.vue';
import PageLayout from '@/components/PageLayout.vue';

export default {
  name: 'PolsterPage',
  components: {
    ImageGallery,
    PageLayout
  },
  setup() {
    const images = ref([]);

    useHead({
      title: 'Polster - Raumausstatter Heinz in Eibenstock',
      meta: [
        {
          name: 'description',
          content: '"Aus Alt mach Neu" - Bei Raumausstatter Heinz in Eibenstock erwecken wir Ihre liebgewonnenen Möbelstücke zu neuem Leben. Unsere Polster-Dienstleistungen umfassen Aufpolstern, Beziehen, Abholung, Lieferung und Schaumstoffzuschnittservice.'
        },
        {
          property: 'og:title',
          content: 'Polster - Raumausstatter Heinz in Eibenstock'
        },
        {
          property: 'og:description',
          content: '"Aus Alt mach Neu" - Bei Raumausstatter Heinz erwecken wir Ihre liebgewonnenen Möbelstücke zu neuem Leben. Erfahren Sie mehr über unsere umfangreichen Polster-Dienstleistungen.'
        },
        {
          property: 'og:type',
          content: 'website'
        }
        // include more Open Graph tags as needed
      ]
    });

    const loadImage = async (imagePath) => {
      const image = await import(`@/assets/images/polster/${imagePath}`);
      return image.default;
    };

    onMounted(async () => {
      const req = require.context('@/assets/images/polster/', false, /\.webp$/);
      for (const filePath of req.keys()) {
        const imageName = filePath.replace('./', '');
        const image = await loadImage(imageName);
        images.value.push(image);
      }
    });

    return {
      images
    }
  }
};
</script>

<style scoped>
.outer_wrap
{
    background: url("@/assets/images/homeslider/RaumausstatterHeinz_Polsterarbeiten.webp") repeat scroll 0 0 / contain  content-box;
}
</style>

