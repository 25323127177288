<template>
  <PageLayout title="Kontakt">
    <div class="kontaktleft">
      <p>
        <strong>Raumausstatter Heinz</strong><br>
        Peggy Heinz<br>
        <a target="_blank" href="https://www.google.com/maps/search/Raumausttater+Heinz/@50.4956489,12.5970521,18.36z?entry=ttu">Schneeberger Str. 5</a><br>
        08309 Eibenstock<br>
        Öffnungszeiten: Montag, Dienstag, Donnerstag, 9-12 & 14.30-17.30 Uhr<br>
        Mittwoch geschlossen<br>
        Freitag 9-12 Uhr<br>
        Samstag nach Vereinbarung
      </p>
      <p>
        Tel.: 037752 / 41 08<br>
        E-Mail: <a :href="'mailto:' + $unscrambleEmail(scrambledEmail)">{{ $unscrambleEmail(scrambledEmail).split('@').join(' (ät) ').split('.').join(' (punkt) ') }}</a>
      </p>
    </div>
    <div class="kontaktright">
      <iframe width="100%" height="300px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=12.595260620117188%2C50.49457207590214%2C12.598843574523926%2C50.49672550433385&amp;layer=mapnik&amp;marker=50.4956489%2C12.5970521" style="border: 1px solid black"></iframe><br/>
      <small><a href="https://www.openstreetmap.org/?mlat=50.4956489&amp;mlon=12.5970521#map=18/50.49565/12.59705" target="_blank">Größere Karte anzeigen</a></small>
    </div>
  </PageLayout>
</template>

<script>
import { useHead } from '@vueuse/head';
import PageLayout from '@/components/PageLayout.vue';

export default {
  name: 'KontaktPage',
  components: {
    PageLayout
  },
  created() {
    const unscrambledEmail = 'kontakt@raumausstatter-heinz.de';
    this.scrambledEmail = this.$scrambleEmail(unscrambledEmail);
  },
  setup() {
    useHead({
      title: 'Kontakt - Raumausstatter Heinz in Eibenstock',
      meta: [
        {
          name: 'description',
          content: 'Nehmen Sie Kontakt mit Raumausstatter Heinz in Eibenstock auf. Wir freuen uns auf Ihre Anfragen und bieten individuelle Beratungen. Entdecken Sie unseren umfassenden Service im Raum Eibenstock und dem gesamten Erzgebirge.'
        },
        {
          property: 'og:title',
          content: 'Kontakt - Raumausstatter Heinz in Eibenstock'
        },
        {
          property: 'og:description',
          content: 'Nehmen Sie Kontakt mit Raumausstatter Heinz in Eibenstock auf. Wir freuen uns auf Ihre Anfragen und bieten individuelle Beratungen. Entdecken Sie unseren umfassenden Service!'
        },
        {
          property: 'og:type',
          content: 'website'
        }
        // include more Open Graph tags as needed
      ]
    });
  }
};
</script>


<style scoped>
/* Kontakt Seite */

.trennstrich {
  border-bottom: 1px solid #000;
  display: inline-block;
  height: 1px;
  margin-top: 1.25em;
  position: relative;
  width: 100%;
}

.outer_wrap
{
  background: url("@/assets/images/homeslider/RaumausstatterHeinz_Ladenfront.webp") repeat scroll 0 0 / contain  content-box;
}
</style>
