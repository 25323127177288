<template>
  <div class="outer_footer_wrap">
    <div v-if="!isContactPage" class="footerkontakt">
      <div id="leistentext">Interesse geweckt? Besuchen Sie uns im Geschäft.</div>
      <a class="call-to-action" href="/kontakt">Adresse ansehen!</a>
    </div>
    <div class="inner_footer_wrap">
      <div id="footer" class="footer-flex">
        <div class="footercolumn not-phone">
          KONTAKT
          <div class="seperator"></div>
          Tel.: 037752 / 41 08<br>
          Öffnungszeiten: Montag, Dienstag, Donnerstag, 9-12 & 14.30-17.30 Uhr<br>
          Mittwoch geschlossen<br>
          Freitag 9-12 Uhr<br>
          Samstag nach Vereinbarung
        </div>
        <div class="footercolumn not-phone">
          GESCHÄFTSADRESSE
          <div class="seperator"></div>
          <a target="_blank" href="https://www.google.com/maps/search/Raumausttater+Heinz/@50.4956489,12.5970521,18.36z?entry=ttu">Schneeberger Str. 5</a><br>
          08309 Eibenstock<br>

        </div>
        <div class="footercolumn not-phone not-tablet">
          LEISTUNGEN
          <div class="seperator"></div>
          <a href="/gardinen">Gardinen & Dekoration</a><br>
          <a href="/sonnenschutz">Sonnenschutz</a><br>
          <a href="/raumtextilien">Raumtextilien</a><br>
          <a href="/polster">Polsterarbeiten</a><br>
        </div>        
      </div>
      <div class="footertext">
        <font color="">&copy; {{currentYear}} Raumausstatter Heinz</font>
        <font color="">|</font>
        <font color="">
          <a href="/impressum" style="" title="Impressum">Impressum</a>
        </font>
        <font color="#010101">|</font>
        <a href="/datenschutz" style="" title="Datenschutz">Datenschutz</a>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "AppFooter",
  data() {
    return {
      isContactPage: this.$route.path === '/kontakt', 
      currentYear: new Date().getFullYear()
    }
  },
  watch: {
    '$route'(to) {
      this.isContactPage = to.path === '/kontakt';
    }
  }
}
</script>

<style lang="scss">

@import '@/assets/styles/globals.scss';

#footer {
  margin: 0 auto;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  justify-content: space-between;
}

.footer-flex > .footercolumn {
  flex: 1;
}
.seperator {
  width: 90%;
  border-top: 1px solid #666;
  margin-bottom: 1rem;
}
.footertext {
  color: #666;
  font-size: 1.25rem;
  text-align: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.footercolumn {
  /*min-height: 180px;*/
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  text-align: left;
  color: #666 !important;
}

.footertitle {
  color: #666 !important;
  font-size: 1rem;
  text-align: left;
  margin-bottom: 1rem;
}

.footerwidgetwrap {
  margin-left: 15%;
}

.footertext a,
.footertext a:hover,
.footertext a:focus,
.footertext a:visited {
  color: #666;
}

.outer_footer_wrap {
  box-shadow: 0 -2px 7px rgba(0, 0, 0, 0.7);
  background: #fcfcfc;
  background: rgba(230, 230, 230, 0.8) none repeat scroll 0 0;
}

.inner_footer_wrap {
}

#footer ul {
  margin: 0;
  list-style: none;
}

.footer1,
.footer2,
.footer3 {
  text-align: left;
}

.footerkontakt {
  background: $secondary-color none repeat scroll 0 0;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #fcfcfc;
  font-size: 2rem;
}
.footerkontakt a {
  display: inline;
  margin-left: 1rem;
}

#leistentext {
  display: inline;
}

#leistentext {
  display: inline;

  @include respond-to(mobile-only) {
    display: block;
    width: 100%;
    margin-bottom: 1.25rem;
  }
}

</style>
