<template>
  <div class="outer_wrap">
    <div class="inner_wrap">
      <div class="content">
        <h2 class="entry-title">{{ title }}</h2>
          <div class="post">
            <div class="entry">
              <slot />
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLayout',
  props: ['title']
}
</script>

<style lang="scss">

@import '@/assets/styles/globals.scss';

.content {
  background: rgba(230, 230, 230, 0.85) none repeat scroll 0 0;
  display: block;
  padding: 4rem;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  margin: 2.5% 5%;
  position: relative;
  z-index: 1;
}

@include respond-to('phone-only') {
.content {
  margin: 0;
  padding: 3rem;
  position: relative;
  box-shadow: 5px 2px 2px 1px rgba(0, 0, 0, 0.5) !important;
  width: 100%;
}
}
</style>
