<template>
  <div id="burger-menu-wrapper">
    <button @click="$emit('toggle')" class="burger-menu burger-rotate" :class="{ 'open': isOpen }">
      <div class="burger-lines"></div>
    </button>
  </div>
</template>

<script>
export default {
  props: ['isOpen']
};
</script>

<style lang="scss">
@import '@/assets/styles/globals.scss';

#burger-menu-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index:999;

  .burger-menu {
    background-color: $primary-color;
    border: none;
    height: 8rem;
    width: 8rem;
    border-bottom-left-radius: 100%;
    cursor: pointer;
    overflow: hidden;
    position: relative; // Add this to allow absolutely positioning the burger lines
    box-shadow: -2px 2px 15px rgba(0, 0, 0, 1.0);
    z-index: 998;

    .burger-lines {
      background-color: $normal-white;
      height: 0.4rem;
      width: 2rem;
      position: absolute;  // Change to absolute
      top: 50%;  // Center vertically
      left: 50%;  // Center horizontally
      transform: translate(-50%, -50%);  // Offset for perfect center
      transition: all .2s;

      &::before,
      &::after {
        content: '';
        position: absolute;
        height: 0.4rem;
        width: 100%;  // Same width as parent
        background-color: $normal-white;
        transition: all .2s;
      }

      &::before {
        top: -0.6rem;  // Distance between lines
      }

      &::after {
        bottom: -0.6rem;  // Distance between lines
      }
    }
  }

.burger-rotate {
    &.open {
      .burger-lines {
        background-color: transparent;
        transform: rotate(315deg);
        transform-origin: 6px 8px;  // Add this line

        &::before,
        &::after {
          top: 0;  // Position to center
          transform: rotate(0deg);  // Cross
        }

        &::after {
          transform: rotate(-90deg);  // Cross
        }
      }
    }
  }
}
</style>
