<template>
  <div id="app">
    <app-header></app-header>
    <router-view></router-view>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style lang="scss">

@import '@/assets/styles/globals.scss';

* {
  box-sizing: border-box;
}

:root {
  font-size: 62.5%;
}

html, body {
  font-family: $font-family;
}

body {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  border-top: 0.35rem solid $primary-color;
  border-top: 0.35rem solid rgba($primary-color, 0.75);
  border-bottom: 0.35rem solid $primary-color;
  border-bottom: 0.35rem solid rgba($primary-color, 0.75);
}

@include respond-to('tablet-only') {
  :root {
  font-size: 50%;
}
}

@include respond-to('phone-only') {
    :root {
    font-size: 40%;
  }
}

html {
  padding: 0;
  margin: 0;
  background: #fff;
}

a {
  text-decoration: none !important;
}
/* Flex Grid */
.outer_wrap {
  height: 100%;
  margin: 0 auto;
  width: 100% !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
}

/* Backgrounds */

.home {
  background: none !important;
}

.inner_wrap {
  padding: 20px 0px 20px 0px;
}

@include respond-to('phone-only') {
.inner_wrap {
  padding: 0;
}
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex_5, .flex_10, .flex_20, .flex_25, .flex_33, .flex_40, .flex_50, .flex_60, .flex_66, .flex_75, .flex_80, .flex_90, .flex_95, .flex_100 {
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.flex_5 {
  flex: 0 0 5%;
}

.flex_10 {
  flex: 0 0 10%;
}

.flex_20 {
  flex: 0 0 20%;
}

.flex_25 {
  flex: 0 0 25%;
}

.flex_33 {
  flex: 0 0 33%;
}

.flex_40 {
  flex: 0 0 40%;
}

.flex_50 {
  flex: 0 0 50%;
}

.flex_60 {
  flex: 0 0 60%;
}

.flex_66 {
  flex: 0 0 66%;
}

.flex_75 {
  flex: 0 0 75%;
}

.flex_80 {
  flex: 0 0 80%;
}

.flex_90 {
  flex: 0 0 90%;
}

.flex_95 {
  flex: 0 0 95%;
}

.flex_100 {
  flex: 0 0 100%;
}

.flex_pad {
  padding: 10px;
}

.flex_vert_pad {
  padding: 20px 0;
}

.margin_10 {
  margin-left: 10%;
}

.margin_20 {
  margin-left: 20%;
}

.margin_30 {
  margin-left: 30%;
}

.margin_33 {
  margin-left: 33%;
}

.margin_50 {
  margin-left: 50%;
}

.primary-color {
  color: $primary-color;
}

.entry-title {
  font-weight: bold;
  position: relative;
  color: $primary-color;
  margin-bottom: 2rem;
}

.entry {
  color: #333;
}

a,
a:visited {
  text-decoration: underline;
  color: $primary-color;
}

a:hover {
  text-decoration: none;
  color: $primary-darker-color;
}

thead {
  display: table-header-group;
}

tr,
img {
  page-break-inside: avoid;
}

img {
  max-width: 100% !important;
}

h2,
h3 {
  page-break-after: avoid;
}

p,
h2,
h3 {
  orphans: 3;
  widows: 3;
}

p {
  margin: 0 0 2rem;
  line-height: 2.0rem;
  font-size: 1.5rem;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0.625rem 0; // 10px -> 0.625rem
  font-family: inherit;
  font-weight: bold;
  line-height: 1.25rem; // 20px -> 1.25rem
  color: inherit;
  text-rendering: optimizelegibility;
}

h1,
h2,
h3 {
  line-height: 1.875rem; // 30px -> 1.875rem
}

h1 {
  font-size: 2.625rem; // 42px -> 2.625rem
}

h2 {
  font-size: 2rem; // 31.5px -> 1.96875rem
}

h3 {
  font-size: 1.5rem; // 24.5px -> 1.53125rem
}

h4 {
  font-size: 1.1rem; // 17.5px -> 1.09375rem
}

h5 {
  font-size: 0.875rem; // 14px -> 0.875rem
}

h6 {
  font-size: 0.75rem; // 11.9px -> 0.74375rem
}

h1 small {
  font-size: 1.5rem; // 24.5px -> 1.53125rem
}

h2 small {
  font-size: 1.1rem; // 17.5px -> 1.09375rem
}

h3 small,
h4 small {
  font-size: 0.875rem; // 14px -> 0.875rem
}

h1 a {
  text-decoration: none;
}

h4 a:hover,
h4 a:active {
  color: $primary-highlight-color !important;
}

h4 a {
  color: $primary-color !important;
}
.headline {
  padding-bottom: 0.5625rem; // 9px -> 0.5625rem
  margin: 1.25rem 0 1.875rem; // 20px -> 1.25rem, 30px -> 1.875rem
  border-bottom: 0.0625rem solid #eeeeee; // 1px -> 0.0625rem
}

ul,
ol {
  font-size: 1.5rem;
  padding: 0;
  margin: 0 0 1rem 5rem; // margin left 5rem -> 80px, please confirm if it's expected
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}

li {
  line-height: 2rem;
}

dl {
  margin-bottom: 1.25rem; // 20px -> 1.25rem
  font-size: 0.875rem; // 14px -> 0.875rem
  line-height: 1.25rem; // 20px -> 1.25rem
}

dt,
dd {
  line-height: 1.25rem; // 20px -> 1.25rem
}

dt {
  font-weight: bold;
  margin-top: 0.625rem; // 10px -> 0.625rem
}

dd {
  margin: 0.3125rem 0 0 1.25rem; // 5px -> 0.3125rem, 20px -> 1.25rem
}

hr {
  margin: 1.25rem 0; // 20px -> 1.25rem
  border: 0;
  border-top: 0.0625rem solid #eeeeee; // 1px -> 0.0625rem
  border-bottom: 0.0625rem solid #ffffff; // 1px -> 0.0625rem
}

textarea {
  height: auto;
  width: 80%;
}

object,
video {
  max-width: 100%;
  width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

.textcenter {
  text-align: center;
}

.textleft {
  text-align: left;
}

.textright {
  text-align: right;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.call-to-action {
  background: $primary-color;
  padding: 0.5rem;
  border-radius: 0.25rem;
  color: #fcfcfc !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

</style>
