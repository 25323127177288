<template>
  <swiper
    :style="{
      '--swiper-navigation-color': '#fff',
      '--swiper-pagination-color': '#fff',
    }"
    :loop="true"
    :spaceBetween="10"
    :slidesPerView="4"
    :breakpoints="{
      // when window width is >= 992px
      992: {
        slidesPerView: 4,
        spaceBetween: 10
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      // when window width is < 480px
      0: {
        slidesPerView: 1,
        spaceBetween: 10
      },
    }"
    :freeMode="true"
    :watchSlidesProgress="true"
    :thumbs="{ swiper: thumbsSwiper }"
    :modules="modules"
    :autoplay="{
        delay: 1500,
      }"
    class="mySwiper2"
  >
    <swiper-slide v-for="(image, index) in images" :key="index">
      <img :src="image" type="image/webp" class="slide-image" />
    </swiper-slide>
  </swiper>
</template>

<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';
  import "swiper/css/free-mode"
  import "swiper/css/navigation"
  import "swiper/css/thumbs"

  // import required modules
  import {FreeMode,Navigation,Thumbs} from 'swiper/modules';

  export default {
    name: 'ImageGallery',
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      images: {
        type: Array,
        required: true,
      },
    },
    setup() {
      let thumbsSwiper = null;

      const setThumbsSwiper = (swiper) => {
        thumbsSwiper = swiper;
      };

      return {
        setThumbsSwiper,
        thumbsSwiper, // This is added to make use of thumbsSwiper
        modules: [FreeMode,Navigation,Thumbs],
      };
    },
  }
</script>

<style scoped>
.slide-image {
  height: 590px; /* Set your desired height */
  width: 100%; /* Maintain aspect ratio */
  object-fit: cover; /* Crop the image to cover the area */
}
</style>