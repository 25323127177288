<template>
  <PageLayout title="Sonnenschutz">
    <p>Ob in Ihrer Wohnung, Ihrem Haus oder Ihrem Büro - ich biete Ihnen eine Vielzahl von Sonneschutzlösungen, die sowohl funktional als auch ästhetisch ansprechend sind:</p>

    <ul>
      <li>Plissees, Rollos oder Jalousien für einen effektiven Sonnenschutz</li>
      <li>Aufmaß, Montage- und Reparaturservice für einen Rundum-Service, der keine Wünsche offen lässt</li>
    </ul>

    <p>Profitieren Sie von maßgeschneiderten Lösungen, die genau auf Ihre Anforderungen und die besonderen Bedingungen des Erzgebirges zugeschnitten sind.</p>
    <ImageGallery :images="images" />
  </PageLayout>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useHead } from '@vueuse/head';
import ImageGallery from '@/components/ImageGallery.vue';
import PageLayout from '@/components/PageLayout.vue';

export default {
  name: 'SonnenschutzPage',
  components: {
    ImageGallery,
    PageLayout
  },
  setup() {
    const images = ref([]);

    useHead({
      title: 'Sonnenschutz - Raumausstatter Heinz in Eibenstock',
      meta: [
        {
          name: 'description',
          content: 'Raumausstatter Heinz bietet Ihnen maßgeschneiderte und ästhetisch ansprechende Sonnenschutzlösungen in Eibenstock und der gesamten Region des Erzgebirges. Unser Service umfasst Plissees, Rollos, Jalousien und vieles mehr.'
        },
        {
          property: 'og:title',
          content: 'Sonnenschutz - Raumausstatter Heinz in Eibenstock'
        },
        {
          property: 'og:description',
          content: 'Raumausstatter Heinz bietet Ihnen maßgeschneiderte und ästhetisch ansprechende Sonnenschutzlösungen in Eibenstock und der gesamten Region des Erzgebirges. Entdecken Sie unseren Service!'
        },
        {
          property: 'og:type',
          content: 'website'
        }
        // include more Open Graph tags as needed
      ]
    });

    const loadImage = async (imagePath) => {
      const image = await import(`@/assets/images/sonnenschutz/${imagePath}`);
      return image.default;
    };

    onMounted(async () => {
      const req = require.context('@/assets/images/sonnenschutz/', false, /\.webp$/);
      for (const filePath of req.keys()) {
        const imageName = filePath.replace('./', '');
        const image = await loadImage(imageName);
        images.value.push(image);
      }
    });

    return {
      images
    }
  }
};
</script>

<style scoped>
.outer_wrap {
  background: url("@/assets/images/homeslider/RaumausstatterHeinz_Flaechenvorhang.webp") repeat scroll 0 0 / contain  content-box;
}
</style>
