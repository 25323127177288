<template>
  <!--
  <p style="color:red; display:inline;"><span :style="{ visibility: isDesktop ? 'visible' : 'hidden' }">Layout: Desktop</span></p>
  <p style="color:red; display:inline;"><span :style="{ visibility: isMobile ? 'visible' : 'hidden' }">Layout: Mobile</span></p>
  <p style="color:red; display:inline;"><span :style="{ visibility: isTablet ? 'visible' : 'hidden' }">Layout: Tablet</span></p>
  <p style="color:red; display:inline;"><span :style="{ visibility: isPhone ? 'visible' : 'hidden' }">Layout: Phone</span></p>-->
  <div id="burger-menu-wrapper">
    <burger-menu :isOpen="isOpen" @toggle="toggleMenu" />
  </div>
  <div class="outer_header_wrap" :class="{'show-content': isOpen}">    
    <div id="kontaktleiste" class="not-phone">      
        &#10230;&nbsp;Jederzeit erreichbar unter unter <b>037752 / 41 08</b>
        | &#10230;&nbsp;<router-link to="/kontakt">Unser Geschäft</router-link>
        | E-Mail:&nbsp;
        <a :href="'mailto:' + $unscrambleEmail(scrambledEmail)">{{ $unscrambleEmail(scrambledEmail).split('@').join(' (ät) ').split('.').join(' (punkt) ') }}</a>
    </div>
    <div class="inner_header_wrap">
      <div id="header" class="header-grid">
        <div id="headerlogo" class="header-left">
            <router-link to="/">
              <div class="logo-container">
                <img 
                  class="logo" 
                  :src="require('@/assets/images/naehmaschine.webp')" 
                  alt="Logo von Raumausstatter Heinz" 
                />
                  
              </div>
              <div class="signet-container">
                <img
                    class="signet"
                    :src="require('@/assets/images/230725_Logo_Raumausstatter_Heinz.svg')" 
                    alt="Raumausstatter Heinz" 
                  />
              </div>
            </router-link>
        </div>
        <nav class="header-right">
          <ul class="header-menu" :class="{'menu-open': isOpen}">
            <li v-for="item in menuItems" :key="item.title">
              <router-link :to="item.link" active-class="nav-active" @click="navigateAndToggleMenu(item.link)">{{ item.title }}</router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
// Import the BurgerMenu component
import BurgerMenu from './BurgerMenu.vue';
import { MENU_ITEMS } from '@/constants.js';

export default {
  name: 'AppHeader',
  data() {
    return {
      menuItems: MENU_ITEMS,
      layoutSize: process.env.NODE_ENV === 'development' ? '' : null,
      scrambledEmail: null,
      unscrambledEmail: 'kontakt@raumausstatter-heinz.de',
      isOpen: false, // Added the isOpen state
      isDesktop: false,
      isTablet: false,
      isPhone: false,
    };
  },
  created() {
    this.scrambledEmail = this.$scrambleEmail(this.unscrambledEmail);
  },
  mounted() {
    if (process.env.NODE_ENV === 'development') {
      this.checkVisibility();
      window.addEventListener('resize', this.checkVisibility);
    }
  },
  beforeUnmount() {
    if (process.env.NODE_ENV === 'development') {
      window.removeEventListener('resize', this.checkVisibility);
    }
  },
  methods: {
    checkVisibility() {
      const elDesktop = document.createElement('div');
      elDesktop.className = 'desktop-only';
      document.body.appendChild(elDesktop);
      this.isDesktop = window.getComputedStyle(elDesktop).display !== 'none';
      document.body.removeChild(elDesktop);

      const elTablet = document.createElement('div');
      elTablet.className = 'tablet-only';
      document.body.appendChild(elTablet);
      this.isTablet = window.getComputedStyle(elTablet).display !== 'none';
      document.body.removeChild(elTablet);

      const elPhone = document.createElement('div');
      elPhone.className = 'phone-only';
      document.body.appendChild(elPhone);
      this.isPhone = window.getComputedStyle(elPhone).display !== 'none';
      document.body.removeChild(elPhone);
    },
    toggleMenu() { // Added the toggleMenu function
      this.isOpen = !this.isOpen;
    },
    navigateAndToggleMenu(link) {
    this.$router.push(link);
    this.toggleMenu();
    },
  },
  components: {
    BurgerMenu
  },
  computed: {
  isMobile() {
    return this.isTablet || this.isPhone;
  }
}
}
</script>

<style lang="scss">

@import '@/assets/styles/globals.scss';

#header {
  padding: 2rem;
}

.outer_header_wrap {
  /*background: #fcfcfc;*/
  background: rgba(230, 230, 230, 0.85) none repeat scroll 0 0;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.7);
  position: relative;
  z-index: 9;
  @include respond-to('phone') {
    position: sticky;
    top: 0;
    z-index: 998;
  }
}

.outer_header_wrap {
  /* other styles... */
 /* max-height: 45vh;
  overflow: hidden;
  transition: max-height 1.15s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
}

.outer_header_wrap.show-content {
}

.inner_header_wrap {
}

.header-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 2rem;
  gap: 2rem;
}

.header-left {
  grid-column: 1;
}

.header-right {
  grid-column: 2;
  width: 100%;
}
@include respond-to('tablet-only') {
  .header-grid {
    grid-template-columns: 1fr;
  }
  .header-left, .header-right {
    padding: 1rem;
  }
}

  #burger-menu-wrapper{
    display: none;
  }

#headerlogo {
  width: 12rem;
}

.site-title
{
  color: $primary-color;
  font-size: 2.5rem;
  font-weight: normal !important;
  margin: 0;
  margin-left: 1rem; /* Adds some space between the logo and the title */
  letter-spacing: -0.03rem;
  font-weight:  100;
  line-height: 3rem;
}

.site-title  {
  text-decoration: none;
}

.site-title:hover {
  color: $primary-darker-color;
  text-decoration: none;
}


.logo-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include respond-to('phone-only') {
    display: none;
  }
}

.logo {
  width: 10rem;
  height: auto;
}

/* Navigation */
/* ---------------------------------------------------------- */

.header-right {
  display: flex;
  justify-content: flex-end;
 /* overflow:hidden;*/
}

.header-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include respond-to('tablet-only') {
    flex-wrap: wrap;
  }
  @include respond-to('phone-only') {
    width: 100%;
    flex-direction: column;
  }
}

.header-menu li {
  margin-left: 0px; /* Adjust as needed */
}

nav {
  margin: 0;
  padding: 0;
  width: 100%;
}

nav a:hover,
nav a:active {
  color: #fcfcfc;
}

nav ul {
  font-size: 1.5rem;
  list-style: none;
  margin: 0px 0px 0px 0px;
  padding-left: 0;
  text-transform: none;
}

nav ul a:hover,
.nav-active {
  background: $primary-color;
  color: #fff !important;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.9);
  border-radius: 0.25em;
}

nav li {

}

nav a {
  /*font-weight: bolder;*/
  display: block;
  line-height: 4.2rem;
  padding: 0 1.2125rem;
  text-decoration: none;
  color: #666;
  transition: background 0.25s, transform 0.15s;
}

#kontaktleiste {
  padding-left: 2rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125raem;
  color: #333;
  font-size: 1rem;
  border-bottom: 1px solid rgba($primary-color, 0.75);
  background: rgba(230, 230, 230, 0.95);
}

@include respond-to('tablet-only') {
  .header-grid {
    grid-template-columns: 1.1fr 1fr;
  }
}
/* Phone */
@include respond-to('phone') {

.header-grid {
  grid-template-columns: 1fr;
}
  .header-left {
    grid-column: 1;
    padding: 1rem;
  }

#headerlogo {
  margin-left: 1rem;
  margin-right: 0;
  width: inherit;
}
  .header-right {
    grid-column: 1;
  }

  #burger-menu-wrapper{
    display: inherit;
  }

  .header-menu {
    display: none;

    li {
      width: 100%; // make list items full-width
      text-align: center; // center text
      border-bottom: 1px solid #ccc; // add bottom border

    }
  }

  .header-menu.menu-open {
    display: block;
  }

  #header {
  padding: 0;
}

.site-title
{
  font-size: 2rem;
}

} /* End Phone */
</style>
