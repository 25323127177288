<template>
  <PageLayout title="Seite nicht gefunden!">
    <div class="not-found">
        <h1>404</h1>
        <p>Die Seite wurde nicht gefunden.</p>
        <router-link to="/">Zurück zur Startseite</router-link>
    </div>
  </PageLayout>
</template>


<script>
import PageLayout from '@/components/PageLayout.vue';

export default {
  name: 'NotFound',
  components: {
    PageLayout
  }
}
</script>

<style scoped>
/* Kontakt Seite */

.not-found {
  text-align: center;
  padding: 2rem;
}

</style>
