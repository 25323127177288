<template>
  <PageLayout title="Raumtextilien">
    <p>In meinem Geschäft entdecken Sie zu jeder Jahreszeit und für jeden Einrichtungsstil einzigartige Dekorationsideen, die Ihr Heim noch gemütlicher machen. Ihrer Kreativität sind dabei kaum Grenzen gesetzt. Lassen Sie sich inspirieren und erleben Sie ein frisches Wohnambiente mit Kissen, Decken und weiteren Raumtextilien.</p>
    <ImageGallery :images="images" />
  </PageLayout>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useHead } from '@vueuse/head';
import ImageGallery from '@/components/ImageGallery.vue';
import PageLayout from '@/components/PageLayout.vue';

export default {
  name: 'RaumtextilienPage',
  components: {
    ImageGallery,
    PageLayout
  },
  setup() {
    const images = ref([]);

    useHead({
      title: 'Raumtextilien - Raumausstatter Heinz in Eibenstock',
      meta: [
        {
          name: 'description',
          content: 'Erleben Sie ein frisches Wohnambiente mit einzigartigen Raumtextilien von Raumausstatter Heinz. Entdecken Sie unsere vielfältige Auswahl an Kissen, Decken und weiteren Raumtextilien in Eibenstock und der Region Aue/Schwarzenberg.'
        },
        {
          property: 'og:title',
          content: 'Raumtextilien - Raumausstatter Heinz in Eibenstock'
        },
        {
          property: 'og:description',
          content: 'Erleben Sie ein frisches Wohnambiente mit einzigartigen Raumtextilien von Raumausstatter Heinz. Entdecken Sie unsere vielfältige Auswahl an Kissen, Decken und weiteren Raumtextilien.'
        },
        {
          property: 'og:type',
          content: 'website'
        }
        // include more Open Graph tags as needed
      ]
    });

    const loadImage = async (imagePath) => {
      const image = await import(`@/assets/images/accessories/${imagePath}`);
      return image.default;
    };

    onMounted(async () => {
      const req = require.context('@/assets/images/accessories/', false, /\.webp$/);
      for (const filePath of req.keys()) {
        const imageName = filePath.replace('./', '');
        const image = await loadImage(imageName);
        images.value.push(image);
      }
    });

    return {
      images
    }
  }
};
</script>


<style scoped>
.outer_wrap
{
    background: url("@/assets/images/homeslider/RaumausstatterHeinz_Raumtextilien.webp") repeat scroll 0 0 / contain  content-box;
}
</style>

