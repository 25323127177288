import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import KontaktPage from '../views/KontaktPage.vue'
import GardinenPage from '../views/GardinenPage.vue'
import SonnenschutzPage from '../views/SonnenschutzPage.vue'
import RaumtextilienPage from '../views/RaumtextilienPage.vue'
import PolsterPage from '../views/PolsterPage.vue'
import ImpressumPage from '../views/ImpressumPage.vue'
import DatenschutzPage from '../views/DatenschutzPage.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/kontakt',
    name: 'KontaktPage',
    component: KontaktPage
  },
  {
    path: '/gardinen', // new route
    name: 'GardinenPage',
    component: GardinenPage
  },
  {
    path: '/sonnenschutz', // new route
    name: 'SonnenschutzPage',
    component: SonnenschutzPage
  },
    {
    path: '/raumtextilien', // new route
    name: 'RaumtextilienPage',
    component: RaumtextilienPage
  },
  {
    path: '/polster', // new route
    name: 'PolsterPage',
    component: PolsterPage
  },
  {
    path: '/impressum', // new route
    name: 'ImpressumPage',
    component: ImpressumPage
  },
  {
    path: '/datenschutz', // new route
    name: 'DatenschutzPage',
    component: DatenschutzPage
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
