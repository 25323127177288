<template>
  <div id="swiper-container">
    <swiper-container
      :init="false"
      navigation="true"
      pagination="true"
      scrollbar="true"
      :centered-slides="true"
      :on="swiperCallbacks"
      :breakpoints="swiperBreakpoints"
      :slides-per-view="1"
      :loop="true"
      :autoplay="{
        delay: 6500,
      }"
      thumbs-swiper=".my-thumbs"
      ref="swiper"
    >
      <swiper-slide v-for="(slide, index) in slides" :key="index">
        <picture>
          <img class="swiper-slide-img" :src="slide.fallbackImage" :alt="slide.title" type="image/webp">
        </picture>
        <div class="slide-content">
          <h2 class="slider-title">{{ slide.title }}</h2>
          <p class="slider-description">{{ slide.description }}</p>
          <a class="homewidget call-to-action" :href="slide.link">{{ slide.linktext }}</a>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'HomePage',
  metaInfo: {
    // if a route has metaInfo defined, these values will be used instead
    title: 'Raumausstatter Heinz',
    meta: [
      {
        name: 'description',
        content: 'Raumausstatter Heinz - Ihr Experte für Inneneinrichtung und Polsterarbeiten in Eibenstock, bedient die gesamte Aue/Schwarzenberg Region und das gesamte Erzgebirge. Verwandeln Sie Ihre Wohnräume in ein Spiegelbild Ihrer Persönlichkeit. Entdecken Sie heute unsere Dienstleistungen.'
      },
      {
        property: 'og:description',
        content: 'Raumausstatter Heinz - Ihr Experte für Inneneinrichtung und Polsterarbeiten in Eibenstock, bedient die gesamte Aue/Schwarzenberg Region und das gesamte Erzgebirge. Verwandeln Sie Ihre Wohnräume in ein Spiegelbild Ihrer Persönlichkeit. Entdecken Sie heute unsere Dienstleistungen.'
      }
    ]
  },
  setup() {
    const slides = ref([
      {
        title: 'Willkommen auf der Webseite von Raumausstatter Heinz',
        fallbackImage: require('@/assets/images/homeslider/RaumausstatterHeinz_Ladenfront.webp'),
        description: "Die Gestaltung von Innenräumen ist eine Kunst, in der Form, Licht und Farbe zu einem individuellen Stil verschmelzen.\n\nIch, Peggy Heinz, bin Ihre Polstermeisterin & Expertin für Inneneinrichtung. Mit einem Auge für Details und einer Leidenschaft für Design, verwandle ich Ihre Räume in ein Ambiente, das Ihre Persönlichkeit widerspiegelt. Meine Handwerkskunst übe ich seit über drei Jahrzehnten mit großer Begeisterung aus.\n\nIch stehe Ihnen auch in der gesamten Region Aue/Schwarzenberg und weiteren Gebieten zur Verfügung.",
        link: '/gardinen',
        linktext: "Erfahren Sie mehr"
      },
      {
        title: 'Kontakt',
        fallbackImage: require('@/assets/images/homeslider/RaumausstatterHeinz_Oeffnungszeiten.webp'),
        description: 'Besuchen Sie uns in unserem Geschäft und lassen Sie uns gemeinsam die perfekte Lösung für Ihren Raum finden. Unser Service umfasst persönliche Beratung und maßgeschneiderte Lösungen. ',
        link: '/kontakt',
        linktext: "Kontakt aufnehmen"
      },
      {
        title: 'Gardinen & Dekoration',
        fallbackImage: require('@/assets/images/homeslider/RaumausstatterHeinz_Gardinen.webp'),
        description: "Mit Beratung und Auswahl individueller Dekorationskonzepte verwandelt sich Ihr Raum in ein einzigartiges Ambiente. Von der Gestaltung bis zur Montage und Reinigung Ihrer Fensterdekoration - ich biete einen umfassenden Service.",
        link: '/gardinen',
        linktext: "Unsere Kollektion"
      },
      {
        title: 'Sonnenschutz',
        fallbackImage: require('@/assets/images/homeslider/RaumausstatterHeinz_Flaechenvorhang.webp'),
        description: "Ich biete maßgeschneiderte Lösungen, wie Plissees, Markisen, Rollos, Jalousien, inklusive Aufmaß, Montage- und Reparaturservice, perfekt angepasst an Ihre individuellen Anforderungen.",
        link: '/sonnenschutz',
        linktext: "Beispiele ansehen"
      },
      {
        title: 'Raumtextilien',
        fallbackImage: require('@/assets/images/homeslider/RaumausstatterHeinz_Raumtextilien.webp'),
        description: "In meinem Laden finden Sie ganzjährig und für jeden Stil individuelle Deko-Ideen, die Ihr Zuhause noch behaglicher gestalten. Ihrer Fantasie sind dabei fast keine Limits gesetzt. Lassen Sie sich anregen und genießen Sie eine erfrischende Wohnatmosphäre mit Polstern, Überwürfen und anderen Heimtextilien.",
        link: '/raumtextilien',
        linktext: "Inspirieren lassen"
      },
      {
        title: 'Polsterarbeiten',
        fallbackImage: require('@/assets/images/homeslider/RaumausstatterHeinz_Polsterarbeiten.webp'),
        description: "Verwandeln Sie Ihr altes Möbelstück in ein neues Glanzstück! Mit unserem Service zum Aufpolstern, Beziehen und Schaumstoffzuschnitt bringen wir Ihre Lieblinge wieder in Topform. Kontaktieren Sie uns jetzt für Abholung und Lieferung!",
        link: '/polster',
        linktext: "Stöbern"
      },
    ]);

    const swiper = ref(null);

    onMounted(() => {
      // swiper parameters
      const swiperParams = {
        slidesPerView: 1,
        breakpoints: {
          640: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 1,
          },
        },
        on: {
          init() {
            console.log('Swiper initialized');
          },
        },
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiper.value, swiperParams);

      // and now initialize it
      swiper.value.initialize();
    });
    
    useHead({
      title: 'Raumausstatter Heinz',
      meta: [
        {
          name: 'description',
          content: 'Raumausstatter Heinz - Ihr Experte für Inneneinrichtung und Polsterarbeiten in Eibenstock, bedient die gesamte Aue/Schwarzenberg Region und das gesamte Erzgebirge. Verwandeln Sie Ihre Wohnräume in ein Spiegelbild Ihrer Persönlichkeit. Entdecken Sie heute unsere Dienstleistungen.'
        },
        {
          property: 'og:description',
          content: 'Raumausstatter Heinz - Ihr Experte für Inneneinrichtung und Polsterarbeiten in Eibenstock, bedient die gesamte Aue/Schwarzenberg Region und das gesamte Erzgebirge. Verwandeln Sie Ihre Wohnräume in ein Spiegelbild Ihrer Persönlichkeit. Entdecken Sie heute unsere Dienstleistungen.'
        }
      ]
    });
    return { swiper, slides };
  },
};
</script>

<style lang="scss">

@import '@/assets/styles/globals.scss';

.swiper-container {
  width: 90vw;
  height: 100vh;
}
.swiper-slide-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-title {
  margin-bottom: 2rem;
}

.slide-content {
  position: absolute;
  bottom: 10%;
  left: 7.5%;
  margin-right: 7.5%;
  color: white;
  text-align: left;
  z-index: 10;
}

.slide-content {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  border-radius: 2rem;
  backdrop-filter: blur(0.33rem);
  box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.5);
}

</style>
